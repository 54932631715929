import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './CaseStudiesContent.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';
import Text from '../../../elements/Text';
import LearnMoreLink from '../../../elements/LearnMoreLink';
import Testimonials from './Testimonials';

const CaseStudiesContent = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/case-studies-list.md/" } }) {
        edges {
          node {
            frontmatter {
              caseStudies {
                image
                author
                occupation
                description
                href
              }
            }
          }
        }
      }
    }
  `);

  const { caseStudies } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.caseStudiesContent}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            {
              caseStudies.map((caseStudy, index) => (
                <div key={index} className="col-xl-4 col-lg-4 col-6 d-flex justify-content-center">
                  <a href={caseStudy.href} className={styles.caseStudy} target="_blank" rel="noopener noreferrer">
                    <div className="d-flex flex-column justify-content-between h-100">
                      <div>
                        <Image name={caseStudy.image} className="mb-4" />
                        <div className={`${styles.caseStudy__title} mb-1`}>{caseStudy.author}</div>
                        <div className={`${styles.caseStudy__subtitle} mb-3`}>{caseStudy.occupation}</div>
                        <Text className={`${styles.caseStudy__text} pr-4`}>{caseStudy.description}</Text>
                      </div>
                      <LearnMoreLink href={caseStudy.href} className={styles.caseStudy__link} text="Read Case Study" />
                    </div>
                  </a>
                </div>
              ))
            }
            <div className="col-xl-4 col-lg-4 d-xl-block d-lg-block d-none">
              <Image name="midpage_yellow_man" className={styles.caseStudy__manImage} />
              <Image name="midpage_plant" className={styles.caseStudy__plantImage} />
            </div>
          </div>
          <Testimonials />
        </Container>
      </Container>
    </div>
  )
};

export default CaseStudiesContent;