import React, { Fragment } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './Testimonials.module.css';

import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';
import Container from '../../../../blocks/Container';

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/videos.md/" } }) {
        edges {
          node {
            frontmatter {
              description
              testimonials {
                title
                title2
                subtitle
                previewImage
                href
              }
            }
          }
        }
      }
    }
  `);

  const { description, testimonials } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.testimonials}>
      <Title className={styles.testimonials__topTitle}>Testimonials</Title>
      <Container type="mobile" className="d-flex justify-content-center">
        <Text className={styles.testimonials__subtitleContainer}>{description}</Text>
      </Container>
      <div className={styles.testimonialsContainer}>
        {
          testimonials.map((testimonial, index) => (
            <a key={index} href={testimonial.href} className={styles.testimonials__item} data-lity>
              <div className={styles.testimonials__videoPreview}>
                <Image name={testimonial.previewImage} />
                <Image name="play" className={styles.testimonials__playImage} />
                <div className={styles.testimonials__playImageMobile} />
              </div>
              <div className={styles.testimonials__description}>
                <div className={styles.testimonials__title}>{testimonial.title}</div>
                {testimonial.title2 && <div className={styles.testimonials__title}>{testimonial.title2}</div>}
                <div className={styles.testimonials__subtitle}>{testimonial.subtitle}</div>
              </div>
              {
                index === testimonials.length - 1 &&
                <Fragment>
                  <Image name="midpage_yellow_man" className={styles.testimonials__manImage} />
                  <Image name="midpage_plant" className={styles.testimonials__plantImage} />
                </Fragment>
              }
            </a>
          ))
        }
        <div className={styles.testimonials__itemEmpty} />
        <div className={styles.testimonials__image} />
      </div>
    </div>
  )
};

export default Testimonials;