import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import CaseStudiesHeader from '../components/slides/case-studies/CaseStudiesHeader';
import CaseStudiesContent from '../components/slides/case-studies/CaseStudiesContent';

const CaseStudies = () => {
  return (
    <Layout>
      <SEO
        title="Case Studies | Deadline Funnel"
        description="Course creators, marketers, and business owners are using Deadline Funnel every day to grow their impact through real evergreen marketing. Are you next?"
      />
      <CaseStudiesHeader />
      <CaseStudiesContent />
    </Layout>
  )
};

export default CaseStudies;